@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #080A0D;
}

*{
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

label, .main, a {
  color: #453f65 !important;
}

.p-toast .p-toast-message .p-toast-message-content {
  border-width: 0px;
}

.p-column-header-content {
  text-align: center !important;
  display: flex;
  justify-content: center;
}